import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("fr");
  const [selectedTheme, setSelectedTheme] = useState("light");

  useEffect(() => {
    document.body.className = selectedTheme;
  }, [selectedTheme]);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  function toggleLanguage(e) {
    e.preventDefault();
    if (selectedLanguage === "en") {
      setSelectedLanguage("fr");
    } else if (selectedLanguage === "fr") {
      setSelectedLanguage("en");
    }
  }

  function toggleTheme(e) {
    e.preventDefault();
    if (selectedTheme === "light") {
      setSelectedTheme("dark");
    } else if (selectedTheme === "dark") {
      setSelectedTheme("light");
    }
  }

  return (
    <header>
      <menu>
        <div className="">
          <Link to="/">{t("header.home")}</Link>
          <Link to="/about">{t("header.about")}</Link>
        </div>

        <div className="btns-container" id="customize">
          <button className="customize-btn" key={selectedLanguage} onClick={toggleLanguage}>
            <FontAwesomeIcon className="icon" icon={faEarthAmericas} />
            {selectedLanguage === "en" ? "en" : "fr"}
          </button>

          <button className="customize-btn" onClick={toggleTheme}>
            <FontAwesomeIcon className="icon" icon={selectedTheme === "light" ? faSun : faMoon} />
          </button>
        </div>
      </menu>
    </header>
  );
};

export default Header;
