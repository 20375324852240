import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import Modal from "./Modal";

const Card = ({ project }) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) document.body.style.overflow = "hidden";
    if (!isModalVisible) document.body.style.overflow = "unset";
  }, [isModalVisible]);

  return (
    <div className="card">
      <img src={project.imageUrl} alt="" onClick={openModal} />
      <div className="card-content">
        <h3 onClick={openModal}>{t("project.title", { project })}</h3>
        <p>{t("project.description", { project })}</p>

        {isModalVisible && <Modal project={project} closeModal={closeModal} isModalVisible={isModalVisible} />}
      </div>
    </div>
  );
};

export default Card;
