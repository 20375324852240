const projects = [
  {
    title: {
      En: "Cubes Waves",
      Fr: "Vagues de Cubes",
    },
    imageUrl: "/img/open_processing/cubes_waves.png",
    projectUrl: "https://openprocessing.org/sketch/1846688/",
    sketchTitle: "cubes_waves",
    description: {
      En: 'A looping animation of a "wave of cubes" made from a Coding Challenge on The Coding Train Youtube channel. The height oscillation of the boxes is calculated using a simple harmonic motion, and depends on the position of the box relative to the center. The illusion of movement of the boxes is created by gradually increasing the angle of rotation with each iteration of the loop.\n\nI needed to create my own boxes to be able to apply custom colors which drastically reduces performance. To see the smoother animation initialize the customBoxes variable to false.',
      Fr: "Une animation en boucle d'une \"vague de cubes\" réalisée à partir d'un Coding Challenge sur la chaîne Youtube The Coding Train. L'oscillation de la hauteur des boîtes est calculée à l'aide d'un mouvement harmonique simple, et dépend de la position de la boîte par rapport au centre. L'illusion de mouvement des boîtes est créée en augmentant progressivement l'angle de rotation à chaque itération de la boucle. \n\nJ'avais besoin de créer mes propres boîtes pour pouvoir appliquer des couleurs personnalisées ce qui réduit drastiquement les performances. Pour voir l'animation plus fluide initialisez la variable customBoxes à false.",
    },
    tags: ["all", "art", "generative"],
  },
  {
    title: {
      En: "Particles & Circle",
      Fr: "Particules & Cercle",
    },
    imageUrl: "/img/open_processing/particles_from_circle.png",
    projectUrl: "https://openprocessing.org/sketch/1847811/",
    sketchTitle: "particles_from_circle",
    description: {
      En: "The particles move along a trajectory determined by random movements, created with Perlin noise. Each particle is initialized with a random position on a circle and a direction based on the angle of that position. The particles also have different sizes and speeds. \n\nI find that seeing the image being generated and watching the particles move is just as satisfying as the final rendering... \n\nAnd obviously I couldn't limit myself to a single color.",
      Fr: "Les particules se déplacent selon une trajectoire déterminée par des mouvements aléatoires, créés avec du bruit de Perlin. Chaque particule est initialisée avec une position aléatoire sur un cercle et une direction basée sur l'angle de cette position. Les particules ont également des tailles et des vitesses différentes.\n\nJe trouve que voir l'image se générer et regarder les particules se déplacer est tout aussi satisfaisant que le rendu final... \n\nEt évidemment je ne pouvais pas me limiter à une seule couleur.",
    },
    tags: ["all", "art", "generative"],
  },
  {
    title: {
      En: "Objects of Queerness",
      Fr: "Objects of Queerness",
    },
    imageUrl: "/img/objects_of_queerness/cover.png",
    projectUrl: "https://niloccolin.itch.io/objects-of-queerness",
    images: [
      { url: "/img/objects_of_queerness/mainMenu.png" },
      { url: "/img/objects_of_queerness/MalocaQMC.png" },
      { url: "/img/objects_of_queerness/map.png" },
      { url: "/img/objects_of_queerness/abyaYala.png" },
      { url: "/img/objects_of_queerness/DianaNavarroQMC.png" },
    ],
    description: {
      En: "Objects of Queerness is a demo created for The Queerness and Games Jam by: Angélica Silva (writing),  Angel(a) (music), NiloccoliN (programming), and Hazel Barr (art). \n\nThe intention of the game is to use objects to create queer representation, and design a relaxing and creative experience. \n\nIn Objects of Queerness you are the curator of a Queer Memory Center set in the Island of Maloca. In this version of the future every community has a Queer Memory Center or QMC. Communities are mostly self-sufficient and trading is vital for creating connections between the different communities. \n\nAs the curator of a QMC you will start the game with a few objects on your inventory and trade with other QMCs to complete collections and reach achievements. The objects are based on real life queer culture: books, movies, art, music, textile etc. \n\nIn this process you will travel to four different locations and meet with other curators. These trips and the conversations with the characters will give you a glimpse of how much things have changed. \n\nObjects of Queerness is a game where you can learn and celebrate queer culture. It is also a world where you can dream of a queer accepting future. \n\n(Presentation written by Angélica Silva)",
      Fr: "Objects of Queerness est une démo créée pour The Queerness et Games Jam par : Angélica Silva (écriture), Angel(a) (musique), NiloccoliN (programmation) et Hazel Barr (art). \n\nL'intention du jeu est d'utiliser des objets pour créer une représentation queer et concevoir une expérience relaxante et créative. \n\nDans Objects of Queerness, vous êtes le conservateur d'un Centre de Mémoire Queer situé sur l'île de Maloca. Dans cette version du futur, chaque communauté dispose d'un Centre de Mémoire Queer (Queer Memory Center ou QMC). Les communautés sont pour la plupart autosuffisantes et le troc est essentiel pour créer des liens entre les différentes communautés. \n\nEn tant que conservateur d'un QMC, vous commencerez le jeu avec quelques objets dans votre inventaire et échangerez avec d'autres QMC pour compléter des collections et atteindre des succès. Les objets sont basés sur la culture queer réelle : livres, films, art, musique, textile, etc. \n\nAu cours de ce processus, vous voyagerez dans quatre endroits différents et rencontrerez d'autres conservateurs. Ces voyages et les conversations avec les personnages vous donneront un aperçu de combien les choses ont changé. \n\nObjects of Queerness est un jeu dans lequel vous pouvez apprendre et célébrer la culture queer. C'est aussi un monde où l'on peut rêver d'un avenir queer et acceptant. \n\n(Présentation écrite par Angélica Silva)",
    },
    tags: ["all", "games"],
  },
  {
    title: {
      En: "Poisson Disc",
      Fr: "Disques de Poisson",
    },
    imageUrl: "/img/open_processing/poisson_disc_coral.png",
    projectUrl: "https://openprocessing.org/sketch/1849090/",
    sketchTitle: "poisson_disc_coral",
    description: {
      En: 'I used an algorithm by Robert Brisdon called poisson disc sampling: it is a form of "blue noise", a pattern that can be found for example in the arrangement of cells in the human retina. \n\nThis technique therefore makes it possible to randomly select points that are very close to each other, so that they respect a minimum distance specified by the user. The placement of these points depending on chance, predefined rules, and the environment (here, a square space) the result is always different. \n\nI love watching the process of this algorithm simulating (roughly) rules of nature, and seeing the dots invade the space; and if we join with a line each new point to the neighboring point that generated it, we can observe how the branches are formed. The pattern that appears here could be related to an organic pattern, several organisms that spread through this small square space, and seek to occupy it in its entirety.',
      Fr: "J'ai utilisé un algorithme de Robert Brisdon appelé en anglais poisson disc sampling : il s'agit d'une forme de \"bruit bleu\", un motif que l'on peut retrouver par example dans l'arrangement des cellules de la rétine humaine. \n\nCette technique permet donc de sélectionner de manière aléatoire des points très proches les uns des autres, de sorte qu'ils respectent une distance minimale spécifiée par l'utilisateur. Le placement de ces points dépendant à la fois du hasard, de règles prédéfinies, et de l'environnement (ici, un espace carré) le résultat est toujours différent... \n\nJ'aime regarder le processus de cet algorithme simulant (grossièrement) des règles de la nature, et voir les points envahir l'espace ; et si on rejoint grâce à une ligne chaque nouveau point au point voisin qui l'a généré, on peut observer la façon dont les embranchements se forment. Le motif qui apparaît ici pourrait s'apparenter à un motif organique, plusieurs organismes qui se propagent à travers ce petit espace carré, et cherchent à l'occuper dans sa totalité.",
    },
    tags: ["all", "art", "generative"],
  },
  {
    title: {
      En: "Connections",
      Fr: "Connexions",
    },
    imageUrl: "/img/open_processing/connections.png",
    projectUrl: "https://openprocessing.org/sketch/1856932/",
    sketchTitle: "connections",
    description: {
      En: "I've always loved the inaccuracies (and difficulty) of mouse drawing. I wanted to take advantage of the possibilities offered by this very simple algorithm to exploit and embellish these small accidents.\n\nThis is not generative art here, but interactive drawing: you will have to draw yourself! \n\nIn addition to the line created in response to mouse movements, new lines appear connected to previous ones: for each previous position, the distance between this position and the current mouse position is calculated. If the distance is less than or equal to a certain value, the program calculates a random encounter probability and draws a line between the current mouse position and the previous position with this probability.",
      Fr: "J'ai toujours aimé les imprécisions (et la difficulté) du dessin à la souris. Je voulais profiter des possibilités qu'offre cet algorithme très simple pour exploiter et embellir ces petits accidents. \n\nIci il ne s'agit pas d'art génératif, mais de dessin interactif : vous allez devoir dessiner vous même ! \n\nEn plus de la ligne créée en réponse aux mouvements de la souris, de nouvelles lignes apparaissent reliées aux précédentes : pour chaque position précédente, on calcule la distance entre cette position et la position actuelle de la souris. Si la distance est inférieure ou égale à une certaine valeur, le programme calcule une probabilité de rencontre aléatoire et dessine une ligne entre la position actuelle de la souris et la position précédente avec cette probabilité.",
    },
    tags: ["all", "art", "generative", "drawing"],
  },
  {
    title: {
      En: "Artificial Topography",
      Fr: "Topographie Artificielle",
    },
    imageUrl: "/img/open_processing/artificial_topography.png",
    projectUrl: "https://openprocessing.org/sketch/1300027/",
    sketchTitle: "artificial_topography",
    description: {
      En: "",
      Fr: "",
    },
    tags: ["all", "art", "generative"],
  },
  {
    title: {
      En: "Chroma Siblings",
      Fr: "Chroma Siblings",
    },
    imageUrl: "/img/chroma_siblings/title_screen.png",
    projectUrl: "https://niloccolin.itch.io/chroma-siblings",
    images: [
      { url: "/img/chroma_siblings/level_1.png" },
      { url: "/img/chroma_siblings/level_2.png" },
      { url: "/img/chroma_siblings/win.png" },
    ],
    description: {
      En: 'A game made with p5.js for the p5.js Game Jam #5.\n\nThe themes were "Playing Both Sides" and "Contrast".\n\nThis is a puzzle game, where you have to match the colors of the two circles, in order to find the combo of the two winning notes. Not much logic here, but your memory and focusing ability might play a crucial role.\n\nProgressing in the game may be difficult if you are color blind. I\'m sorry!\n\nBesides being a puzzle game it is also a musical playground. Each cell of the synth plays a sound when a sibling walks over it. You can also have fun experimenting with different signal waves and different scales (only C Major and C minor for now).',
      Fr: "Un jeu fait avec p5.js pour la p5.js Game Jam #5.\n\nLes thèmes étaient \"Playing Both Sides\" et \"Contrast\".\n\nIl s'agit d'un jeu de puzzle, où vous devez faire correspondre les couleurs des deux cercles, afin de trouver la combinaison des deux notes gagnantes. Pas beaucoup de logique ici, mais votre mémoire et votre capacité de concentration pourraient jouer un rôle crucial.\n\nProgresser dans le jeu peut être difficile si vous êtes daltonien. Je suis désolé!\n\nEn plus d'être un jeu de puzzle, c'est aussi un terrain de jeu musical. Chaque cellule du synthé joue un son lorsqu'un \"sibling\" marche dessus. Vous pouvez également vous amuser à expérimenter différentes ondes de signal et différentes gammes (uniquement Do Majeur et Do Mineur pour l'instant).",
    },
    tags: ["all", "games"],
  },
  {
    title: {
      En: "Armene",
      Fr: "Armene",
    },
    imageUrl: "/img/armene/armene_title.png",
    projectUrl: "https://niloccolin.itch.io/armene",
    images: [
      { url: "/img/armene/main_menu.png" },
      { url: "/img/armene/sitting.png" },
      { url: "/img/armene/leon_and_ophelia.png" },
      { url: "/img/armene/sitting_garden.png" },
      { url: "/img/armene/common_room_1.png" },
      { url: "/img/armene/common_room_2.png" },
    ],
    description: {
      En: 'This is a very small game made with Unity and C# during the Adventure Jam 2023. \n\n"Armene wakes up on a bench without remembering where she is. Make her live an ordinary adventure by walking and chatting with the people around." \n\nThe main idea behind this game was to create a quiet atmosphere, in a place where not much is going on. The gameplay consists of walking around and talking with other characters, in order to help the main character to find her bearings, and also, her memory.',
      Fr: "Il s'agit d'un tout petit jeu réalisé avec Unity et C# lors de l'Adventure Jam 2023. \n\n\"Armene se réveille sur un banc sans se rappeler où elle se trouve. Faites-lui vivre une aventure ordinaire en marchant et en discutant avec les gens autour.\" \n\nL'idée principale derrière ce jeu était de créer une atmosphère tranquille, dans un endroit où il ne se passe pas grand chose. Le gameplay consiste à se balader et dialoguer avec d'autres personnages, afin d'aider le personnage principal à trouver ses repères, et aussi, sa mémoire.",
    },
    tags: ["all", "games"],
  },
  {
    title: {
      En: "Distortion",
      Fr: "Déformation",
    },
    imageUrl: "/img/open_processing/distortion.png",
    projectUrl: "https://openprocessing.org/sketch/1856934/",
    sketchTitle: "distortion",
    description: {
      En: "An image distortion processing code using a vector field based on Perlin noise. \n\nThe vector field generates a random vector for each pixel in the resulting image, which will determine its color: the resulting color of each pixel is calculated from the corresponding color of the original image. \n\nA scalar field is also used to calculate a scalar value of noise in order to perturb the vector field: thus the combination of the perturbations of the scalar field and of the vector field makes it possible to produce a distortion. \n\nYou can play with the values of the scalar field and the vector field, as well as the details of the noise, to obtain different effects.",
      Fr: "Un code Processing de distorsion d'image utilisant un champ vectoriel basé sur le bruit de Perlin. \n\nLe champ vectoriel génère un vecteur aléatoire pour chaque pixel de l'image résultante, ce qui déterminera sa couleur : la couleur résultante de chaque pixel est calculée à partir de la couleur correspondante de l'image originale. \n\nUn champ scalaire est aussi utilisé pour calculer une valeur scalaire de bruit afin de perturber le champ vectoriel : ainsi la combinaison des perturbations du champ scalaire et du champ vectoriel permet de produire une déformation. \n\nOn peut jouer avec les valeurs du champ scalaire et du champ vectoriel, ainsi que les détails du bruit, pour obtenir des effets différents.",
    },
    tags: ["all", "art", "generative"],
  },
  {
    title: {
      En: "Fractal Tree",
      Fr: "Arbre Fractal",
    },
    imageUrl: "/img/open_processing/fractal_tree.png",
    projectUrl: "https://openprocessing.org/sketch/1293752/",
    sketchTitle: "fractal_tree",
    description: {
      En: "This code draws a fractal tree by starting with a main branch and then drawing smaller branches from each existing branch. Each new branch is drawn with a color and thickness appropriate to its size. The process continues as long as the branches are large enough, thus creating a complex tree with many branches.",
      Fr: "Ce code dessine un arbre fractal en commençant par une branche principale, puis en traçant des branches plus petites à partir de chaque branche existante. Chaque nouvelle branche est dessinée avec une couleur et une épaisseur adaptées à sa taille. Le processus continue tant que les branches sont suffisamment grandes, créant ainsi un arbre complexe avec de nombreuses branches.",
    },
    tags: ["all", "art", "generative"],
  },

  {
    title: {
      En: "Laser Defender",
      Fr: "Laser Defender",
    },
    imageUrl: "/img/laser_defender/laser_defender.jpg",
    projectUrl: "https://niloccolin.itch.io/laser-defender",
    images: [
      { url: "/img/laser_defender/ld_main_menu.jpg" },
      { url: "/img/laser_defender/ld_capture_1.jpg" },
      { url: "/img/laser_defender/ld_capture_2.jpg" },
    ],
    description: {
      En: 'A very basic "Space Shooter" type videogame created with C# and Unity as part of a course on the Udemy Platform.',
      Fr: 'Un jeu vidéo très basique de type "Space Shooter" créé avec C# et Unity dans le cadre d\'un cours sur la plateforme Udemy.',
    },
    tags: ["all", "games"],
  },
  {
    title: {
      En: "Donuts",
      Fr: "Donuts",
    },
    imageUrl: "/img/3d/donuts1.png",
    images: [
      { url: "/img/3d/donuts1.png" },
      { url: "/img/3d/donuts2.png" },
      { url: "/img/3d/donuts3.png" },
    ],
    description: {
      En: 'All self-taught people started there, right?',
      Fr: "Tous les autodidactes ont commencé par là, non ?",
    },
    tags: ["all", "art", "3D"],
  },
];

export default projects;
