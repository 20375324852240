import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Masonry from "react-masonry-css";
import Card from "../components/Card";
import projects from "../data/projects";

const HomePage = () => {
  const { t, i18n } = useTranslation();

  const [selectedFilter, setSelectedFilter] = useState("all");

  const changeFilter = (e) => {
    e.preventDefault();
    setSelectedFilter(e.target.value);
    const filterBtns = document.getElementsByClassName("filter-btn");
    for (let i = 0; i < filterBtns.length; i++) {
      filterBtns[i].classList.remove("selected");
    }
    e.target.classList.add("selected");
  };

  return (
    <div className="page">
      <h1>{t("title.part1")}</h1>
      <sub>{t("title.part2")}</sub>
      <hr />

      <div className="filter-list">
        <button className="filter-btn selected" value="all" onClick={changeFilter}>
          {t("filter.all")}
        </button>
        <button className="filter-btn" value="art" onClick={changeFilter}>
          {t("filter.art")}
        </button>
        <button className="filter-btn" value="games" onClick={changeFilter}>
          {t("filter.games")}
        </button>
        <button className="filter-btn" value="generative" onClick={changeFilter}>
          {t("filter.generative")}
        </button>
        <button className="filter-btn" value="3D" onClick={changeFilter}>
          {t("filter.3D")}
        </button>
        <button className="filter-btn" value="drawing" onClick={changeFilter}>
          {t("filter.drawing")}
        </button>
      </div>
      <hr />

      <Masonry
        className="card-grid"
        columnClassName="card-grid-column"
        breakpointCols={{ default: 3, 1100: 2, 700: 1 }}
      >
        {Array.isArray(projects) &&
          projects
            ?.filter((project) => project.tags.includes(selectedFilter))
            .map((project, index) => {
              return <Card key={index} project={project} />;
            })}
      </Masonry>
    </div>
  );
};

export default HomePage;
