import { useRef, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import Masonry from "react-masonry-css";

const Modal = ({ project, closeModal }) => {
  const { t } = useTranslation();
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (ref.current && ref.current == e.target) {
        closeModal();
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [closeModal]);

  return (
    <div className="shadow-bg" ref={ref}>
      <div className="modal">
        <div className="modal-header">
          <h3>
            <a target="_blank" rel="noreferrer" href={project.projectUrl}>
              {t("project.title", { project })}
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          </h3>
          <button className="delete-btn xmark" onClick={closeModal}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>

        {project.sketchTitle && (
          <iframe
            src={project.projectUrl + "embed/"}
            className="sketch-canvas"
            title={project.sketchTitle ? project.sketchTitle : "untitled"}
          ></iframe>
        )}

        {project.images && (
          <Masonry
            className="captures-grid"
            columnClassName="captures-grid-column"
            breakpointCols={{ default: 3, 900: 2, 500: 1 }}
          >
            {Array.isArray(project.images) &&
              project.images?.map((image, index) => {
                return (
                  <div className="capture">
                    <img key={index} src={image.url} alt="" />
                  </div>
                );
              })}
          </Masonry>
        )}

        <p>{t("project.description", { project })}</p>
      </div>
    </div>
  );
};

export default Modal;
