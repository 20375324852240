import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";

export const Footer = () => {
  const { t, i18n } = useTranslation();

  let year = new Date().getFullYear();

  return (
    <footer>
      <img src="/img/wave.svg" alt="" />
      <div className="footer-content">
        <div className="footer-legal">
          <div className="creative-commons">
            <a href="https://creativecommons.org/licenses/by-nc/2.0/" target="_blank" rel="noreferrer">
              <img src="/img/icons/cc.svg" alt="cc" className="icon" />
            </a>
            <a href="https://creativecommons.org/licenses/by-nc/2.0/" target="_blank" rel="noreferrer">
              <img src="/img/icons/by.svg" alt="cc" className="icon" />
            </a>
            <a href="https://creativecommons.org/licenses/by-nc/2.0/" target="_blank" rel="noreferrer">
              <img src="/img/icons/nc.svg" alt="cc" className="icon" />
            </a>
            <p className="dash">-</p>
            <p>{year}</p>
          </div>
          <p className="dash">-</p>
          <p>{t("footer.createdby")}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
